/* eslint-disable jsx-a11y/alt-text */
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Header from '../../common/components/header'
import { getNewsByIdFetch } from './API'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import Contact from '../home/components/contact'
import { useLocation } from 'react-router'
import { serverUrl } from '../../constants'
import Video from '../../common/components/video'

export default function News() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const newsId = params.get("newsId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [news, setNews] = useState()

    const getPartnerAll = async () => {
        setLoading(true)

        let result = await getNewsByIdFetch(Number(newsId))
        if (result) {
            setNews(result)
        }
        console.log("result : ", result)

        setLoading(false)
    }

    useEffect(() => {
        getPartnerAll()
    }, [])

    console.log("url(" + serverUrl + "/" + news?.imageDetailURL + ")")

    return (
        <>
            <Header />

            <div
                style={{
                    paddingLeft: width > 760 ? "15%" : 0,
                    paddingRight: width > 760 ? "15%" : 0,
                    backgroundColor: "#EEEEEE"
                }}
            >
                <div style={{ backgroundColor: "white", }}>
                    {!loading ?
                        <>
                            <div style={{ paddingTop: 70 }}>
                                {news?.imageDetailURL ?
                                    // <div
                                    //     style={{
                                    //         backgroundImage: "url(" + serverUrl + "/" + news?.imageDetailURL + ")",
                                    //         backgroundSize: (width > 760 ? '100%' : '255%'),
                                    //         width: '100% auto',
                                    //         height: '100% auto',
                                    //         display: "flex",
                                    //         justifyContent: "center",
                                    //         alignItems: "center",
                                    //         filter: "brightness(110%)"
                                    //     }}
                                    // >
                                    //     <div style={{ height: (width > 900 ? 400 : 200) }}></div>
                                    // </div>
                                    <div style={{ paddingLeft: 24, paddingTop: 24, paddingRight: 24 }}>
                                        <center>
                                            <img
                                                src={serverUrl + "/" + news?.imageDetailURL}
                                                style={{ width: "100%" }}
                                            />
                                        </center>
                                    </div>
                                    : []
                                }
                            </div>
                            <Row
                                gutter={[0, 24]}
                                style={{
                                    backgroundColor: "white",
                                    paddingTop: 24,
                                    paddingBottom: 84,
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                }}
                            >
                                <Col span={24}>
                                    <label style={{ fontWeight: 600, fontSize: 20 }}><a style={{ color: "#002482" }} href='/'>Home / </a>{news?.title ?? "-"}</label>
                                </Col>

                                {/* {news?.videoDetailURL ?
                                    <Col span={24}>
                                        <Video
                                            url={news?.videoDetailURL}
                                            title={""}
                                            height={"100%"}
                                            width={"100%"}
                                        />
                                    </Col>
                                    : []
                                } */}

                                <Col span={24}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ fontWeight: 600 }}>{news?.title ?? "-"}</label>
                                        <label>{news?.detail ? parse(news.detail) : "-"}</label>
                                        <div style={{ display: "flex" }}>
                                            <img
                                                style={{ width: 20, height: 20, borderRadius: 8 }}
                                                src={`./assets/images/news/idea.png`}
                                            />
                                            <label style={{ paddingLeft: 10 }}>หากท่านมีความสนใจบริการของเรา สามารถติตต่อได้ที่ 061-995-4478</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        : []
                    }
                </div>
                <footer>
                    <Contact />
                </footer>

            </div>
        </>
    )
}
