import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function Contact() {
    const { width } = useDimensions()

    return (
        <div
            style={{
                paddingTop: 128,
                paddingBottom: 128,
                backgroundImage: "url(./assets/images/background/low-angle-shot-white-glass-building-with-interesting-textures-great-cool-background.jpg)",
                backgroundSize: (width > 760 ? '100%' : '255%'),
                width: '100% auto',
                height: '100% auto',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                filter: "brightness(110%)"
            }}
        >
            <Row gutter={[24,32]}>
            <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 20, color: "#002482", fontWeight: 600 }}>CONTACT</label>
                </Col>

                <Col xs={24} md={12} xl={12}>
                    <div style={{ display: "grid", alignItems: 'center', justifyContent: "center" }}>
                        <center>
                            <img
                                style={{ width: 80, borderRadius: 8 }}
                                src={`./assets/images/contact/phone.png`}
                            />
                        </center>
                        <label style={{ fontSize: 17, paddingTop: 12 }}>061-995-4478</label>
                    </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                    <div style={{ display: 'grid', alignItems: 'center', justifyContent: "center" }}>
                        <center>
                            <img
                                style={{ width: 80, borderRadius: 8 }}
                                src={`./assets/images/contact/email.png`}
                            />
                        </center>
                        <label style={{ fontSize: 17, paddingTop: 12 }}>sales@sbiconsulting.co.th</label>
                    </div>
                </Col>
            </Row>
        </div>
    )
}