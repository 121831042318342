/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function OurServices() {

    const { width } = useDimensions()

    let [service, setService] = useState([
        {
            image: "./assets/images/our_service/our_service_a.png",
            title: "ISO Implementation and audit such as ISO/IEC 27001, 27701, 22301, 31000, etc."
        },
        {
            image: "./assets/images/our_service/our_service_b.png",
            title: "IT Security and Cybersecurity Awareness Training"
        },
        {
            image: "./assets/images/our_service/our_service_c.png",
            title: "IT Risk Assessment and Management"
        },
        {
            image: "./assets/images/our_service/our_service_d.png",
            title: "IT Compliance and Regulatory Review such as BOT, OIC, SEC, etc."
        },
        {
            image: "./assets/images/our_service/our_service_e.png",
            title: "Data governance and data security management"
        },
        {
            image: "./assets/images/our_service/our_service_f.png",
            title: "In-house training and phishing test"
        },
    ])

    return (
        <div>
            <div style={{ backgroundColor: "white", borderRadius: 8 }}>
                <Row
                    gutter={[12, 12]}
                    style={{
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24),
                        paddingRight: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24)
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <label style={{ fontSize: 20, color: "#002482", fontWeight: 600 }}>OUR SERVICES</label>
                    </Col>

                    {service?.map((item, index) => {
                        return (
                            <Col xs={24} md={12} xl={12} style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "flex-start" }}>
                                <div style={{ borderRadius: 50, border: "2px solid #002482", display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                    <img
                                        src={item.image}
                                        style={{ width: 45, height: 45, borderRadius: 50, padding: 6 }}
                                    />
                                </div>
                                <label style={{ paddingLeft: 10 }}>{item.title}</label>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}
