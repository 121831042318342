import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getNewsAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_NEWS_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getNewsByIdFetch = async (newsId) => {
    try {
        const result = await httpClient.post(server.GET_NEWS_BY_ID_URL, { newsId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getNewsAllFetch,
    getNewsByIdFetch,

    // insert

    // update

    // delete

}