import { server } from "./../../../constants"
import { httpClient } from "./../../../utils/HttpClient"

const getAdminByIdFetch = async (username, password) => { // Done
    try {
        const result = await httpClient.post(server.GET_ADMIN_BY_ID_URL, { username, password })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAdminAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_ADMIN_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAdminByIdFetch,
    getAdminAllFetch

    // insert

    // update

    // delete
}