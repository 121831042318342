import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getNewsBySearchFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_NEWS_BY_SEARCH_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertNewsFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_NEWS_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateNewsByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_NEWS_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteNewsByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.put(server.DELETE_NEWS_BY_ID_URL, { id })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getNewsBySearchFetch,

    // insert
    insertNewsFetch,

    // update
    updateNewsByIdFetch,

    // delete
    deleteNewsByIdFetch
}
