
export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING"
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS"
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const serverUrl = "https://sbi-consulting.witte-bangkok.com"
// export const serverUrl = "http:///localhost:3001"

export const apiServerUrl = serverUrl + "/api/v1"

export const imgDefaultUrl = `./assets/images/default/df-img.png`
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`
export const img404notfound = `./assets/images/notfound/404notfound.jpg`
export const inspectCertificateUrl = `https://www.toflyn.com/inspect-certificate`

export const paginate = 10000 // limit 10 k
export const sortASC = "ASC"
export const sortDESC = "DESC"

export const YES = "YES"
export const NO = "NO"
export const OK = "ok"
export const NOK = "nok"

export const server = {
    // ------------------------ NEWS -----------------------------------------------------------
    GET_NEWS_ALL_URL: `news/get-news-all`,
    GET_NEWS_BY_ID_URL: `news/get-news-by-id`,
    GET_NEWS_BY_SEARCH_URL: `news/get-news-by-search`,

    INSERT_NEWS_URL: `news/insert-news`,

    UPDATE_NEWS_BY_ID_URL: `news/update-news-by-id`,

    DELETE_NEWS_BY_ID_URL: `news/delete-news-by-id`,

    // ------------------------ ERP ------------------------------------------------------------
    GET_ADMIN_BY_ID_URL: `erp/get-admin-by-id`,
    GET_ADMIN_ALL_URL: `erp/get-admin-all`,

    // ------------------------ ADMIN ----------------------------------------------------------
    GET_ADMIN_BY_SEARCH_URL: `erp/get-admin-by-search`,
    INSPECT_NEW_USERNAME_DUPLICATE_URL: `erp/inspect-new-username-duplicate`,
    INSPECT_OLD_PASSWORD_MATCHES_URL: `erp/inspect-old-password-matches`,

    INSERT_ADMIN_URL: `erp/insert-admin`,

    UPDATE_ADMIN_BY_ID_URL: `erp/update-admin-by-id`,
    UPDATE_ADMIN_PASSWORD_BY_ID_URL: `erp/update-admin-password-by-id`,

    DELETE_ADMIN_BY_ID_URL: `erp/delete-admin-by-id`
}
