import React from "react"
import App from "./App"
import firebase from "./config/firebase-config"
import { createRoot } from 'react-dom/client'
import { applyMiddleware } from "redux"
import { createStore } from 'redux'
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import reducer from "./reducers"

let authorized = false
let userInfo = null

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(
    thunk
  ))
)

const root = createRoot(document.getElementById("root"))

firebase.auth().onAuthStateChanged((user) => {
  if (user != null) {
    authorized = true
    userInfo = user
  }

  root.render(
    <React.StrictMode>
        <Provider store={store}>
          <App authorized={authorized} firebase={firebase} user={userInfo} />
        </Provider>
    </React.StrictMode>
  )
})
