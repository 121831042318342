import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"

export default function Main() {

    const { width } = useDimensions()

    return (
        <div
            style={{
                backgroundImage: "url(./assets/images/background/tower-modern-beautiful-skyline-capital.jpg)",
                backgroundSize: (width > 760 ? '100%' : '255%'),
                width: '100% auto',
                height: '100% auto',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                filter: "brightness(110%)"
            }}
        >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Row
                    gutter={[0, 16]}
                    style={{
                        paddingTop: 148,
                        paddingBottom: 84,
                        paddingLeft: width > 760 ? "25%" : 24,
                        paddingRight: width > 760 ? "25%" : 24
                    }}
                >
                    <Col span={24}>

                        <div
                            style={{
                                display: "grid",
                                backgroundColor: width > 760 ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.75)',
                                borderRadius: 16,
                                padding: 16
                            }}
                        >
                            <label style={{ fontSize: 18, fontWeight: 600, color: "#002482", paddingBottom: 6 }}>
                                SBI Consulting Co., Ltd.
                            </label>
                            <label style={{ fontSize: 18 }}>
                                SBI Consulting Co., Ltd. is an information technology consulting company in Thailand with over 10 years’ experience in consultation ISO services and best practices which are ISO 9001, ISO 27001, ISO 20000, ISO 22301 and ISO 31000.
                            </label>

                            <label style={{ fontSize: 18, paddingTop: 16 }}>
                                Our team are already qualified, we encourage them to improve their knowledge and gain certifications from international institutions such as CISSP, CISA, CISM, CDPSE, CEH, ISMS & BCMS Lead Implementer, IRCA: ISMS, SMS, QMS & BCMS Provisional Auditor, ITIL Foundation, COBIT 5 Foundation.
                            </label>
                        </div>

                    </Col>
                    <Col span={24}>
                        <center>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => {
                                    scroller.scrollTo("our-services", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                        offset: -78,
                                    });
                                }}
                            >
                                Get Started
                            </Button>
                        </center>
                    </Col>
                </Row>
            </div>
        </div>

    )
}