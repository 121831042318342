/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { getNewsAllFetch } from "../../../news/API"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import CarouselList from "../../../../common/components/banner/CarouselList"

export default function Newss() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()

    const [news, setNews] = useState([])

    const getPartnerAll = async () => {
        let result = await getNewsAllFetch()
        // console.log("getNewsAllFetch : ", result)
        if (result) {
            setNews(result)
        }
    }

    useEffect(() => {
        getPartnerAll()
    }, [])

    // console.log("news : ", news)

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 68,
                backgroundImage: "url(./assets/images/background/business-skyscraper-building-hong-kong-city.jpg)",
                backgroundSize: (width > 760 ? '100%' : '255%'),
                width: '100% auto',
                height: '100% auto',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                filter: "brightness(110%)"
            }}
        >
            <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 20, color: "#002482", fontWeight: 600 }}>NEWS</label>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0
                        }}
                    >
                        {!loading ?
                            <CarouselList newsData={news} />
                            : []
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}