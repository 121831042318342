import { Button } from 'antd'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import {
    Row,
    Col
} from 'antd'
import { useNavigate } from 'react-router'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function About() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                paddingLeft: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24),
                paddingRight: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24)
            }}
        >
            <Row gutter={[0, 24]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 20, color: "#002482", fontWeight: 600 }}>ABOUT</label>
                </Col>

                <Col xs={24} md={8} xl={8}>
                    <center>
                        <img
                            style={{ width: 160, borderRadius: 8 }}
                            src={`./assets/images/logo/Logo-SBI-Consulting-2.png`}
                        />
                    </center>
                </Col>
                <Col xs={24} md={16} xl={16}>
                    <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <label style={{ fontWeight: 600 }}>บริษัท เอสบีไอ คอนซัลติ้ง จำกัด </label>
                        <label style={{}}>
                            บริษัท เอสบีไอ คอนซัลติ้ง จำกัด ก่อตั้งเมื่อปี พ.ศ. 2561 เราคือผู้เชี่ยวชาญที่มีประสบการณ์ในการให้บริการให้คำปรึกษาตามมาตรฐานสากล งานตรวจสอบตามข้อกำหนดของหน่วยงานกำกับดูแล การวางแผนและพัฒนาระบบเทคโนโลยี สารสนเทศให้กับองค์กรของภาครัฐและเอกชน โดยคำนึงถึง ความต้องการของลูกค้าเป็นหลัก เพื่อสร้างคุณค่าให้กับลูกค้าอย่างประทับใจ
                            ที่ปรึกษาและทีมงาน ของเราเป็นผู้เชี่ยวชาญในงานออกแบบ และพัฒนาระบบมาตรฐาสากล เช่น ISO/IEC 27001, ISO/IEC 22301, ISO 31000, NIST, ITIL, Cobit5 เป็นต้น</label>
                        <label style={{ paddingTop: 24 }}>
                            ผู้เชี่ยวชาญของเรามีประสบการณ์ลงมือปฏิบัติงานจริงและผ่านการอบรมและได้รับใบรับรองมาตรฐานสากลที่เกี่ยวข้องกับการตรวจสอบและพัฒนาระบบต่างๆ เช่น CISSP, CISM, CISA, CDPSE, IRCA ISMS Lead Auditor, IRCA BCMS Lead Auditor เพื่อยกระดับการให้คำปรึกษา การพัฒนา และตรวจสอบระบบเทคโนโลยีสารสนเทศที่สามารถลงนามรับรองตามกฏระเบียบของหน่วยงานกำกับดูแล รวมถึงใส่ใจเพื่อให้ลูกค้าจะได้รับการบริการที่ดี มีคุณภาพ และสามารถนำไปใช้งานได้จริงอย่างมี ประสิทธิภาพ
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
