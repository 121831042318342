import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAbA6yylktvLre1By1B3Cr2NbW_sXR7J7s",
    authDomain: "storykub-2451987.firebaseapp.com",
    projectId: "storykub-2451987",
    storageBucket: "storykub-2451987.appspot.com",
    messagingSenderId: "944674643989",
    appId: "1:944674643989:web:51101bdc85ca8a76856e1c",
    measurementId: "G-CKVJ2ZW5CQ"
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
firebase.analytics()
export { auth }
export default firebase
